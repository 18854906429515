import {
	BlockOutlined,
	CaretRightOutlined,
	ChromeOutlined,
	CloseSquareOutlined,
	DashboardOutlined,
	DownOutlined,
	FileTextOutlined,
	FlagOutlined,
	FormatPainterOutlined,
	GlobalOutlined,
	InteractionOutlined,
	LogoutOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	MobileOutlined,
	NodeIndexOutlined,
	PieChartOutlined,
	PlusOutlined,
	SettingOutlined,
	UserOutlined,
	WindowsOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Divider, Dropdown, Layout, Menu } from 'antd';
import {
	AUTH_LOGIN_PATH,
	AUTH_PATH,
	NEW_PROJECT_PATH,
	PROJECT_BROWSERS_PATH,
	PROJECT_BROWSERS_ROUTE,
	PROJECT_COUNTRY_PATH,
	PROJECT_COUNTRY_ROUTE,
	PROJECT_CUSTOM_PATH,
	PROJECT_CUSTOM_ROUTE,
	PROJECT_DASHBOARD_PATH,
	PROJECT_DASHBOARD_ROUTE,
	PROJECT_DEMOGRAPHICS_PATH,
	PROJECT_DEMOGRAPHICS_ROUTE,
	PROJECT_DEVICES_PATH,
	PROJECT_DEVICES_ROUTE,
	PROJECT_EXCLUDE_PATH,
	PROJECT_EXCLUDE_ROUTE,
	PROJECT_INTEGRATION_PATH,
	PROJECT_OS_PATH,
	PROJECT_OS_ROUTE,
	PROJECT_PAGES_PATH,
	PROJECT_PAGES_ROUTE,
	PROJECT_SETUP_PATH,
	PROJECT_SETUP_ROUTE,
	PROJECT_SOURCES_PATH,
	PROJECT_SOURCES_ROUTE,
	PROJECT_SUPPORT_PATH,
	PROJECT_USERS_PATH,
	PROJECT_USERS_ROUTE,
} from 'constants/routes';
import Image from 'next/image';
import Link from 'next/link';
import { memo, useEffect, useState } from 'react';
const { Content, Sider, Header } = Layout;
import { useRouter } from 'next/router';
import { APP_LOGO } from 'constants/base';
import { useUserContext } from 'context/user';
import { useSessionContext } from 'context/sessions';

const Picture = ({ picture, style, firstName }) => {
	return (
		<Avatar
			size='large'
			src={picture}
			style={{
				marginLeft: '.5rem',
				backgroundColor: 'var(--dark-600)',
				...style,
			}}
		>
			{firstName[0]}
		</Avatar>
	);
};

const UserMenu = ({ slug }) => {
	const {
		user: { plan, firstName, lastName, email, picture },
	} = useUserContext();
	const { type: planType = 0 } = plan || {};
	const handleLogout = () => {
		window.localStorage.clear();
		window.location.href = AUTH_LOGIN_PATH;
	};

	const menu = (
		<Menu>
			<div style={{ padding: '.6rem 1rem .8rem', textAlign: 'center' }}>
				<Picture
					style={{ margin: '0 0 .6rem 0' }}
					firstName={firstName}
					picture={picture}
				/>
				<br />
				{firstName} {lastName}
				<br />
				{email}
			</div>
			<Divider style={{ margin: 0, borderColor: 'var(--primary)' }} />
			<Menu.Item onClick={handleLogout} key='new' icon={<LogoutOutlined />}>
				Logout
			</Menu.Item>
		</Menu>
	);

	let planName = 'Starter';
	if (planType === 1) {
		planName = 'Growth';
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			{/* <Link href={`/projects/${slug}/plans`} passHref>
				<Button style={{ marginRight: '1rem' }} className='mobile-hidden'>
					{planName} Plan
				</Button>
			</Link> */}
			<Dropdown overlay={menu}>
				<div
					style={{
						cursor: 'pointer',
						background: 'var(--dark-900)',
						height: '40px',
						display: 'flex',
						alignItems: 'center',
						borderRadius: '4px 5rem 5rem 4px',
						paddingLeft: '.9rem',
					}}
				>
					{firstName} <Picture firstName={firstName} picture={picture} />
				</div>
			</Dropdown>
		</div>
	);
};

const routes = ({ scriptInstalled, slug }) => {
	if (!scriptInstalled) {
		return [
			{
				label: 'Setup',
				url: PROJECT_SETUP_PATH(slug),
			},
		];
	}
	return [
		{
			label: 'Dashboard',
			url: PROJECT_DASHBOARD_PATH(slug),
			icon: <DashboardOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Users',
			url: PROJECT_USERS_PATH(slug),
			icon: <UserOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Demographics',
			url: PROJECT_DEMOGRAPHICS_PATH(slug),
			icon: <PieChartOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Top Pages',
			url: PROJECT_PAGES_PATH(slug),
			icon: <FileTextOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Top Sources',
			url: PROJECT_SOURCES_PATH(slug),
			icon: <NodeIndexOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Top Countries',
			url: PROJECT_COUNTRY_PATH(slug),
			icon: <GlobalOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Top Devices',
			url: PROJECT_DEVICES_PATH(slug),
			icon: <MobileOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Top Browsers',
			url: PROJECT_BROWSERS_PATH(slug),
			icon: <ChromeOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Top OS',
			url: PROJECT_OS_PATH(slug),
			icon: <WindowsOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Setup',
			url: PROJECT_SETUP_PATH(slug),
			icon: <SettingOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Exclusions',
			url: PROJECT_EXCLUDE_PATH(slug),
			icon: <CloseSquareOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Custom Events',
			url: PROJECT_CUSTOM_PATH(slug),
			icon: <FormatPainterOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Integrations',
			url: PROJECT_INTEGRATION_PATH(slug),
			icon: <InteractionOutlined style={{ fontSize: '1.2rem' }} />,
		},
		{
			label: 'Support',
			url: PROJECT_SUPPORT_PATH(slug),
			icon: <FlagOutlined style={{ fontSize: '1.2rem' }} />,
		},
	];
};

let inter;

const Sidebar = memo(function Sidebar({
	scriptInstalled,
	collapsed,
	setCollapsed,
}) {
	const [selectedKey, setSelectedKey] = useState('0');
	const router = useRouter();
	const {
		pathname,
		query: { slug },
	} = router;

	useEffect(() => {
		let index = 0;
		if (pathname === PROJECT_USERS_ROUTE) {
			index = 1;
		} else if (pathname === PROJECT_DEMOGRAPHICS_ROUTE) {
			index = 2;
		} else if (pathname === PROJECT_PAGES_ROUTE) {
			index = 3;
		} else if (pathname === PROJECT_SOURCES_ROUTE) {
			index = 4;
		} else if (pathname === PROJECT_COUNTRY_ROUTE) {
			index = 5;
		} else if (pathname === PROJECT_DEVICES_ROUTE) {
			index = 6;
		} else if (pathname === PROJECT_BROWSERS_ROUTE) {
			index = 7;
		} else if (pathname === PROJECT_OS_ROUTE) {
			index = 8;
		} else if (pathname === PROJECT_SETUP_ROUTE) {
			index = 9;
		} else if (pathname === PROJECT_EXCLUDE_ROUTE) {
			index = 10;
		} else if (pathname === PROJECT_CUSTOM_ROUTE) {
			index = 11;
		}
		setSelectedKey(`${index + 1}`);
	}, [pathname]);

	return (
		<Sider
			trigger={null}
			style={{
				overflow: 'auto',
				height: '100vh',
				position: 'fixed',
				left: 0,
				top: 0,
				paddingTop: '60px',
				bottom: 0,
			}}
			collapsed={collapsed}
			breakpoint='lg'
			collapsedWidth='0'
		>
			<Menu
				onClick={e => {
					if (window.innerWidth <= 990) setCollapsed(true);
					setSelectedKey(e.key);
				}}
				theme='dark'
				mode='inline'
				selectedKeys={selectedKey}
			>
				{routes({ scriptInstalled, slug }).map((route, i) => {
					if (route.subMenu) return route.subMenu();
					return (
						<Menu.Item key={i + 1} icon={route.icon || <CaretRightOutlined />}>
							<Link href={route.url}>{route.label}</Link>
						</Menu.Item>
					);
				})}
			</Menu>
		</Sider>
	);
});

const Wrapper = memo(function Wrapper({ noSidebar, children }) {
	const [collapsed, setCollapsed] = useState(true);

	const { sessions = [], liveSessions = [] } = useSessionContext();

	const scriptInstalled = sessions.length || liveSessions.length;

	const router = useRouter();
	const { user } = useUserContext();
	const { projects = [] } = user || {};
	const { slug } = router.query || {};

	const currentProject = projects.find(project => project.slug === slug);
	const { name } = currentProject || {};

	const isAuthPath = router.pathname.startsWith(AUTH_PATH);
	const isProjectPath = router.pathname.startsWith(PROJECT_DASHBOARD_ROUTE);
	const isNewProjectPath = router.pathname === NEW_PROJECT_PATH;

	// const isAuthPath = router.pathname.startsWith(AUTH_PATH);
	useEffect(() => {
		if (window.innerWidth > 990) {
			setCollapsed(false);
		}
	}, []);

	useEffect(() => {
		// const localUser = localStorage.getItem('user');
		// if (!localUser) {
		// 	// setLoading(false);
		// 	return;
		// }
		// if (localUser) {
		// 	fetchUser();
		// } else if (!isAuthPath) {
		// 	router.push(AUTH_LOGIN_PATH);
		// } else {
		// 	setLoading(false);
		// }
		if (router.pathname === NEW_PROJECT_PATH && !collapsed) {
			setCollapsed(true);
		} else {
			inter = setInterval(() => {
				if (
					window.innerWidth > 990 &&
					collapsed &&
					router.pathname !== NEW_PROJECT_PATH
				) {
					setCollapsed(false);
				}
			}, 5000);
		}
		return () => {
			clearInterval(inter);
		};
	}, [collapsed, router.pathname]);

	let header = null;

	if (isProjectPath) {
		const selectedProject = slug;

		const handleMenuClick = ({ key }) => {
			if (key === 'new') {
				router.push(NEW_PROJECT_PATH);
			} else {
				router.push(PROJECT_DASHBOARD_PATH(key));
			}
		};

		const menu = (
			<Menu onClick={handleMenuClick} selectedKeys={[selectedProject]}>
				{projects.map(project => (
					<Menu.Item key={project.slug} icon={<CaretRightOutlined />}>
						{project.name}
					</Menu.Item>
				))}
				<Menu.Item key='new' icon={<PlusOutlined />}>
					Add new project
				</Menu.Item>
			</Menu>
		);

		header = (
			<Dropdown overlay={menu}>
				<Button>
				<BlockOutlined /> {name} <DownOutlined />
				</Button>
			</Dropdown>
		);
	}

	if (isAuthPath) {
		return children;
	}

	return (
		// <Layout hasSider>
		<Layout hasSider>
			{!noSidebar && (
				<Sidebar
					scriptInstalled={scriptInstalled}
					setCollapsed={setCollapsed}
					collapsed={collapsed}
				/>
			)}
			<Header>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flex: 1,
					}}
				>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div
							className='mobile-hidden'
							style={{
								height: '44px',
								width: '120px',
								marginRight: '5rem',
								position: 'relative',
							}}
						>
							{/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
							<a href='/'>
								<Image alt='veonr logo' src={APP_LOGO} layout='fill' />
							</a>
						</div>
						{!isNewProjectPath && (
							<button
								className='sider-trigger desktop-hidden'
								onClick={() => setCollapsed(prev => !prev)}
							>
								{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
							</button>
						)}
						{header}
					</div>
					<div>
						<UserMenu slug={slug} />
					</div>
				</div>
			</Header>
			<Content
				style={{
					overflow: 'initial',
					marginLeft: isNewProjectPath ? '1rem' : '',
				}}
			>
				{children}
				<br />
				<br />
			</Content>
		</Layout>
		// </Layout>
	);
});

export default Wrapper;

export const withWrapper = function (Comp) {
	return function wrapped(props) {
		return (
			<Wrapper>
				<Comp {...props} />
			</Wrapper>
		);
	};
};
