import API from 'api';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useUserContext } from 'context/user';
import { useRouter } from 'next/router';
import {
	AUTH_PATH,
	NEW_PROJECT_PATH,
	PROJECT_DASHBOARD_PATH,
	PROJECT_DASHBOARD_ROUTE,
	PROJECT_SETUP_PATH,
	PROJECT_SETUP_ROUTE,
} from 'constants/routes';
import { DAYS } from 'constants/base';

const {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} = require('react');

const SessionContext = createContext({});

export const useSessionContext = () => useContext(SessionContext);

let redirectCounter = 0;

export const SessionsWrapper = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const { user } = useUserContext();
	// const { website } = user || {};
	const [result, setResult] = useState(null);
	const { sessions = [], liveSessions = [] } = result || {};
	const router = useRouter();
	const {
		query: { slug },
	} = router;

	const isProjectPath = router.pathname.startsWith(PROJECT_DASHBOARD_ROUTE);
	const isAuthPath = router.pathname.startsWith(AUTH_PATH);
	const isNewProjectPath = router.pathname === NEW_PROJECT_PATH;
	const isSetupPath = router.pathname === PROJECT_SETUP_ROUTE;

	const fetchSessions = useCallback(async () => {
		const result = await API(`/sessions/${slug}`).catch(error => {});
		if (result) {
			setResult(result);

			const scriptVerified =
				result.sessions.length || result.liveSessions.length;
			if (!scriptVerified && !isSetupPath) {
				router.push(PROJECT_SETUP_PATH(slug));
			} else if (!redirectCounter && !isProjectPath) {
				router.push(PROJECT_DASHBOARD_PATH(slug));
				redirectCounter += 1;
			}
			setTimeout(() => setLoading(false), 500);
		}
	}, [user, slug]); // Don't put "router" here. Causes issues.

	useEffect(() => {
		if (!user || !slug) {
			return;
		}
		fetchSessions();
		const inter = setInterval(() => {
			fetchSessions();
		}, 12_000);
		return () => {
			clearInterval(inter);
		};
	}, [fetchSessions, user]);

	if (isAuthPath || isNewProjectPath) return children;

	// Weekly calculations start
	const week = [];
	let mostWeek = -1;
	let mostWeekCount = -1;
	let leastWeek = -1;
	let leastWeekCount = Infinity;

	for (let i = 0; i < 7; i += 1) {
		const count = sessions.filter(
			s => new Date(s.createdAt).getDay() === i
		).length;
		week.push({
			value: count,
			label: DAYS[i],
		});
		if (count < leastWeekCount) {
			leastWeekCount = count;
			leastWeek = i;
		}
		if (count > mostWeekCount) {
			mostWeekCount = count;
			mostWeek = i;
		}
	}
	// Weekly calculations end

	return (
		<SessionContext.Provider
			value={{ sessions, liveSessions, week, leastWeek, mostWeek }}
		>
			{loading ? (
				<div
					style={{
						justifyContent: 'center',
						display: 'flex',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<Spin
						indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
						size='large'
					/>
				</div>
			) : (
				children
			)}
		</SessionContext.Provider>
	);
};
