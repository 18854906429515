import 'antd/dist/antd.css';
import { SessionsWrapper } from 'context/sessions';
import { UserWrapper } from 'context/user';
import Script from 'next/script';
import 'styles/globals.css';
import { IS_PROD } from 'constants/base';
import Wrapper from 'components/Wrapper';

function MyApp({ Component, pageProps }) {
	return (
		<>
			{IS_PROD && (
				<>
					<Script
						id='inspectlet'
						dangerouslySetInnerHTML={{
							__html: `(function() {window.__insp = window.__insp || [];__insp.push(['wid', 2095366681]);var ldinsp = function(){if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=2095366681&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };setTimeout(ldinsp, 0);})();`,
						}}
					/>
					<Script
						id='veoping'
						dangerouslySetInnerHTML={{
							__html: `(function(){window._veoping=[];window.VEOPING_ID='O0A9KqFxhC';d=document;s=d.createElement('script');s.src='https://api.app.veonr.com/api/pingpong.js';s.async=1;d.getElementsByTagName('head')[0].appendChild(s);})();`,
						}}
					/>
					<Script
						id='crisp'
						dangerouslySetInnerHTML={{
							__html: `window.$crisp=[];window.CRISP_WEBSITE_ID="ff9b2626-e0ce-45d5-ab50-d170e9682418";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
						}}
					/>
				</>
			)}
			<UserWrapper>
				<SessionsWrapper>
					<Wrapper>
						<Component {...pageProps} />
					</Wrapper>
				</SessionsWrapper>
			</UserWrapper>
		</>
	);
}

export default MyApp;
