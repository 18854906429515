export const HOME_PATH = '/';
export const AUTH_PATH = '/auth';
export const AUTH_LOGIN_PATH = '/auth/login';
export const AUTH_REGISTER_PATH = '/auth/register';
export const AUTH_VERIFY_PATH = '/auth/verify';
export const NEW_PROJECT_PATH = '/projects/new';
export const PROJECT_DASHBOARD_PATH = slug => `/projects/${slug}`;
export const PROJECT_DASHBOARD_ROUTE = '/projects/[slug]';
export const PROJECT_DEMOGRAPHICS_PATH = slug => `/projects/${slug}/globe`;
export const PROJECT_DEMOGRAPHICS_ROUTE = '/projects/[slug]/globe';
export const PROJECT_PAGES_PATH = slug => `/projects/${slug}/pages`;
export const PROJECT_PAGES_ROUTE = '/projects/[slug]/pages';
export const PROJECT_SOURCES_PATH = slug => `/projects/${slug}/sources`;
export const PROJECT_SOURCES_ROUTE = '/projects/[slug]/sources';
export const PROJECT_DEVICES_PATH = slug => `/projects/${slug}/devices`;
export const PROJECT_DEVICES_ROUTE = '/projects/[slug]/devices';
export const PROJECT_BROWSERS_PATH = slug => `/projects/${slug}/browsers`;
export const PROJECT_BROWSERS_ROUTE = '/projects/[slug]/browsers';
export const PROJECT_COUNTRY_PATH = slug => `/projects/${slug}/countries`;
export const PROJECT_COUNTRY_ROUTE = '/projects/[slug]/countries';
export const PROJECT_OS_PATH = slug => `/projects/${slug}/os`;
export const PROJECT_OS_ROUTE = '/projects/[slug]/os';
export const PROJECT_SETUP_PATH = slug => `/projects/${slug}/setup`;
export const PROJECT_SETUP_ROUTE = '/projects/[slug]/setup';
export const PROJECT_EXCLUDE_PATH = slug => `/projects/${slug}/exclude`;
export const PROJECT_EXCLUDE_ROUTE = '/projects/[slug]/exclude';
export const PROJECT_CUSTOM_PATH = slug => `/projects/${slug}/custom`;
export const PROJECT_CUSTOM_ROUTE = '/projects/[slug]/custom';
export const PROJECT_INTEGRATION_PATH = slug => `/projects/${slug}/integrations`;
export const PROJECT_INTEGRATION_ROUTE = '/projects/[slug]/integrations';
export const PROJECT_SUPPORT_PATH = slug => `/projects/${slug}/support`;
export const PROJECT_SUPPORT_ROUTE = '/projects/[slug]/support';
export const PROJECT_USERS_PATH = slug => `/projects/${slug}/users`;
export const PROJECT_USERS_ROUTE = '/projects/[slug]/users';
