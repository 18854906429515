import API from 'api';
import { useRouter } from 'next/router';
import {
	AUTH_LOGIN_PATH,
	AUTH_PATH,
	AUTH_VERIFY_PATH,
	NEW_PROJECT_PATH,
	PROJECT_DASHBOARD_PATH,
	PROJECT_DASHBOARD_ROUTE,
} from 'constants/routes';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IS_PROD } from 'constants/base';

const {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} = require('react');

const UserContext = createContext({});

export const useUserContext = () => useContext(UserContext);

// let initialized = false;

export const UserWrapper = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const router = useRouter();
	const { projects = [] } = user || {};
	const { query } = router;
	const { slug } = query;

	if (!IS_PROD) {
		console.log('User:', user);
	}

	const project = projects.find(project => project.slug === slug);

	// const { projects } = user || {};

	const isAuthPath = router.pathname.startsWith(AUTH_PATH);
	const isVerifyPath = router.pathname.startsWith(AUTH_VERIFY_PATH);
	const isProjectPath = router.pathname.startsWith(PROJECT_DASHBOARD_ROUTE);

	const fetchUser = useCallback(async () => {
		const result = await API('/users/profile').catch(error => {});
		// In case of Error 401 Unauthorized - It's handled in the "API" method.
		if (!result) return;

		setUser(result);
		if (window.$crisp) {
			$crisp.push(['set', 'user:email', result.email]);
			$crisp.push([
				'set',
				'user:nickname',
				[`${result.firstName} ${result.lastName || ''}`],
			]);
		}
		if (!result.projects.length) {
			router.push(NEW_PROJECT_PATH);
		} else if (!isProjectPath) {
			if (router.pathname === '/') {
				router.replace(PROJECT_DASHBOARD_PATH(result.projects[0].slug));
			} else {
				router.push(PROJECT_DASHBOARD_PATH(result.projects[0].slug));
			}
		}
		setLoading(false);
	}, [isAuthPath, router]);

	useEffect(() => {
		const localUser = localStorage.getItem('user');
		if (localUser) {
			if (!isVerifyPath) fetchUser();
			else setLoading(false);
		} else if (!isAuthPath) {
			router.push(AUTH_LOGIN_PATH);
		} else {
			setLoading(false);
		}
	}, [isAuthPath, isVerifyPath]); // Don't put "router" or "fetchUser" here. Causes issues.

	return (
		<UserContext.Provider value={{ user, setUser, fetchUser, project }}>
			{loading ? (
				<div
					style={{
						justifyContent: 'center',
						display: 'flex',
						alignItems: 'center',
						height: '100vh',
					}}
				>
					<Spin
						indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
						size='large'
					/>
				</div>
			) : (
				children
			)}
		</UserContext.Provider>
	);
};
