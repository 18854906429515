export const IS_PROD = process.env.NODE_ENV === 'production';
export const APP_LOGO =
	'https://res.cloudinary.com/veonr/image/upload/v1635476610/veonr_logo_muo1gh.svg';
export const BASE_URL = 'https://app.veonr.com';
const API_HOSTNAME_PROD = 'https://api.app.veonr.com';
const API_HOSTNAME_DEV = 'http://localhost:8083';

let AH = API_HOSTNAME_PROD;
// if (!IS_PROD) {
// 	AH = API_HOSTNAME_DEV;
// }

export const API_HOSTNAME_BASE = AH;
export const API_HOSTNAME = `${AH}/api`;
export const PADDLE_PRODUCT_ID = 759994;
export const PADDLE_VENDOR_ID = 143144;

export const SLACK_CLIENT_ID = '1945515298550.3654836966802';
export const SLACK_SCOPES = 'chat:write';

export const DAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];
