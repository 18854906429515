import { AUTH_LOGIN_PATH } from 'constants/routes';
import { API_HOSTNAME } from 'constants/base';

const getHeaders = extra => {
	let user = localStorage.getItem('user');
	try {
		user = user ? JSON.parse(user) : {};
	} catch (e) {
		user = {};
	}
	const { token } = user;
	let headers = {
		// "Content-Type": "application/json",
		// Accept: "application/json",
	};
	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}
	headers = { ...headers, ...extra };
	return headers;
};

const API = (endpoint, method = 'GET', body, extraHeaders = {}, isFormData) =>
	new Promise((resolve, reject) => {
		try {
			const headers = getHeaders(extraHeaders);
			let finalBody;
			if (isFormData) {
				finalBody = body;
			} else if (body) {
				finalBody = JSON.stringify(body);
			} else {
				finalBody = undefined;
			}
			fetch(`${API_HOSTNAME}${endpoint}`, {
				method,
				headers,
				body: finalBody,
				credentials: 'include',
			})
				.then(r => r.json())
				.then(res => {
					if (res && res.status !== 200) {
						if (res.status === 401) {
							localStorage.clear();
							if (!window.location.href.includes('/auth/')) {
								window.location.href = AUTH_LOGIN_PATH;
							}
						}
						return reject(res);
					}
					return resolve(res.data);
				})
				.catch(error => {
					if (error.status === 401) {
						localStorage.clear();
						if (!window.location.href.includes('/auth/')) {
							window.location.href = AUTH_LOGIN_PATH;
						}
					} else {
						return reject(error);
					}
				});
		} catch (e) {
			console.error(e);
		}
	});

export default API;
